// components/ContactForm.js
import React from 'react';
import { Container, Typography, TextField, Button, Box } from '@mui/material';

function ContactForm() {
    return (
        <Box sx={{ py: 8 }} id="contact">
            <Container>
                <Typography variant="h4" align="center" gutterBottom>
                    Get in Touch
                </Typography>
                <Typography variant="body1" align="center" color="text.secondary" paragraph>
                    Have questions or need more information? Send us a message.
                </Typography>
                <Box
                    component="form"
                    sx={{ mt: 4, maxWidth: 600, mx: 'auto' }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        fullWidth
                        label="Name"
                        variant="outlined"
                        sx={{ mb: 2 }}
                        required
                    />
                    <TextField
                        fullWidth
                        label="Email"
                        variant="outlined"
                        sx={{ mb: 2 }}
                        type="email"
                        required
                    />
                    <TextField
                        fullWidth
                        label="Message"
                        variant="outlined"
                        multiline
                        rows={4}
                        sx={{ mb: 2 }}
                        required
                    />
                    <Button variant="contained" color="primary" size="large" fullWidth>
                        Send Message
                    </Button>
                </Box>
            </Container>
        </Box>
    );
}

export default ContactForm;

// components/PrivacyPolicy.js
import React from 'react';
import { Container, Typography, Box } from '@mui/material';

function PrivacyPolicy() {
    return (
        <Box sx={{ py: 8, backgroundColor: 'background.default' }} id="privacy-policy">
            <Container>
                <Typography variant="h4" align="center" gutterBottom>
                    Privacy Policy
                </Typography>
                <Typography variant="body1" align="left" color="text.secondary" paragraph>
                    Your privacy is important to us. We are committed to protecting your personal information and ensuring that it is handled responsibly. This Privacy Policy explains how we collect, use, and safeguard your data when you interact with our platform.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    1. Use of Google Workspace APIs
                </Typography>
                <Typography variant="body1" align="left" color="text.secondary" paragraph>
                    We explicitly affirm that any data obtained through Google Workspace APIs, including Google Calendar and Gmail, is not used to develop, improve, or train generalized artificial intelligence (AI) or machine learning (ML) models. All data accessed is solely used to provide and improve the services offered to you, as outlined in this Privacy Policy, in compliance with Google's API Services User Data Policy.
                </Typography>

                <Typography variant="h6" gutterBottom mt={4}>
                    2. Access to Google Calendar
                </Typography>
                <Typography variant="body1" align="left" color="text.secondary" paragraph>
                    To provide seamless time management services, SmartLabBots requests permission to access your Google Calendar. Once granted, we will have CRUD (Create, Read, Update, Delete) access to your calendar. This enables us to schedule events, update or delete them upon request, and read your calendar to send reminders and notifications. All calendar data is handled with the utmost security and only accessed with your explicit consent.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    3. Access to Gmail
                </Typography>
                <Typography variant="body1" align="left" color="text.secondary" paragraph>
                    To enhance your productivity and provide email-related functionalities, SmartLabBots requests permission to access your Gmail account with the ability to read, compose, and send emails on your behalf. This access enables us to:
                </Typography>
                <ul>
                    <li>Read your emails to identify relevant information for scheduling and event management.</li>
                    <li>Compose and send emails to communicate reminders, notifications, and meeting summaries directly from your account.</li>
                </ul>
                <Typography variant="body1" align="left" color="text.secondary" paragraph>
                    All Gmail data is handled with strict confidentiality and security measures. We access your emails only with your explicit consent, and we do not share your Gmail data with any third parties. We comply with Google's API Services User Data Policy, including the Limited Use requirements, ensuring that your Gmail data is used solely to provide or improve our services to you.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    4. WhatsApp Integration
                </Typography>
                <Typography variant="body1" align="left" color="text.secondary" paragraph>
                    Our bots integrate with WhatsApp to provide reminders, meeting summaries, and time management updates. We will send you WhatsApp messages, receive your responses, and store event-related data to ensure the functionality of our services. Your WhatsApp conversations with our bots may be stored to help manage your tasks and events more efficiently.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    5. Information We Collect
                </Typography>
                <Typography variant="body1" align="left" color="text.secondary" paragraph>
                    We collect the following types of information to enhance your experience:
                </Typography>
                <ul>
                    <li><strong>Personal Information:</strong> Includes your name, phone number, and email address.</li>
                    <li><strong>Calendar Data:</strong> Event details such as time, date, and description from your Google Calendar (only with permission).</li>
                    <li><strong>Gmail Data:</strong> Content and metadata of your emails, including sender, recipient, subject, and body text, as necessary to provide our services.</li>
                    <li><strong>WhatsApp Messages:</strong> Interaction data with our bots, such as event reminders or responses.</li>
                    <li><strong>Usage Data:</strong> Information on how you use our services, including bot interaction logs.</li>
                </ul>

                <Typography variant="h6" gutterBottom>
                    6. How We Use Your Information
                </Typography>
                <Typography variant="body1" align="left" color="text.secondary" paragraph>
                    Your data helps us:
                </Typography>
                <ul>
                    <li>Schedule and manage your events and reminders.</li>
                    <li>Compose and send emails on your behalf for notifications, reminders, and meeting summaries.</li>
                    <li>Personalize your experience with SmartLabBots.</li>
                    <li>Provide notifications, summaries, and updates via WhatsApp and email.</li>
                    <li>Ensure the security of your data and improve service quality.</li>
                </ul>

                <Typography variant="h6" gutterBottom>
                    7. Data Sharing and Third-Party Services
                </Typography>
                <Typography variant="body1" align="left" color="text.secondary" paragraph>
                    We do not share your personal information, including Gmail data, with third parties without your consent. Your data is used solely to provide and improve our services to you. We comply with Google's API Services User Data Policy, including the Limited Use requirements. Third-party services like WhatsApp, Google Calendar, and Gmail may collect data in accordance with their privacy policies.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    8. Data Security
                </Typography>
                <Typography variant="body1" align="left" color="text.secondary" paragraph>
                    We use industry-standard security measures to protect your personal information from unauthorized access or disclosure. All communication between you and SmartLabBots, including Gmail interactions, is encrypted. Access to your Gmail account is secured through OAuth 2.0 protocols, and your authentication tokens are stored securely.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    9. Refunds and Cancellation Policy
                </Typography>
                <Typography variant="body1" align="left" color="text.secondary" paragraph>
                    If you are unsatisfied with our services, you may request a refund within 30 days of your initial purchase. Refunds are subject to our review and are processed through Paddle, our Merchant of Record.
                </Typography>
                <Typography variant="body1" align="left" color="text.secondary" paragraph>
                    Subscription cancellations must be made at least 24 hours before the renewal date to avoid charges for the next billing period. Once a subscription is canceled, no further charges will be applied, but you will retain access to the service for the remainder of the paid period.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    10. Data Retention
                </Typography>
                <Typography variant="body1" align="left" color="text.secondary" paragraph>
                    We retain your data only for as long as necessary to provide our services. You can request deletion of your data by contacting us.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    11. Your Rights
                </Typography>
                <Typography variant="body1" align="left" color="text.secondary" paragraph>
                    You have the right to access, modify, or delete your data at any time. Simply contact us for assistance with managing your personal information.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    12. Changes to This Privacy Policy
                </Typography>
                <Typography variant="body1" align="left" color="text.secondary" paragraph>
                    We may update this Privacy Policy as needed to reflect changes in our practices or legal obligations. Any significant changes will be posted on this page.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    13. Contact Us
                </Typography>
                <Typography variant="body1" align="left" color="text.secondary" paragraph>
                    If you have any questions or concerns about this policy, please contact us at support@smartlabbots.com.
                </Typography>

                <Typography variant="body1" align="left" color="text.secondary" paragraph>
                    Last updated: January 2025
                </Typography>
            </Container>
        </Box>
    );
}

export default PrivacyPolicy;

// components/Testimonials.js
import React from 'react';
import { Container, Typography, Box, Avatar, Grid } from '@mui/material';

function Testimonials() {
    const testimonials = [
        {
            name: 'Alice Johnson',
            position: 'Product Manager at InnovateTech',
            photo: 'https://randomuser.me/api/portraits/women/68.jpg',
            quote: 'SmartLabBots has streamlined our processes and saved us countless hours.',
        },
        {
            name: 'Michael Smith',
            position: 'CTO at FutureCorp',
            photo: 'https://randomuser.me/api/portraits/men/45.jpg',
            quote: 'The bots are incredibly intuitive and have become integral to our daily operations.',
        },
        // Add more testimonials as needed
    ];

    return (
        <Box sx={{ py: 8, backgroundColor: 'background.paper' }}>
            <Container>
                <Typography variant="h4" align="center" gutterBottom>
                    Testimonials
                </Typography>
                <Grid container spacing={4} sx={{ mt: 4 }}>
                    {testimonials.map((testimonial, index) => (
                        <Grid item xs={12} md={6} key={index}>
                            <Box sx={{ textAlign: 'center', px: 2 }}>
                                <Avatar
                                    src={testimonial.photo}
                                    alt={testimonial.name}
                                    sx={{ width: 80, height: 80, mx: 'auto', mb: 2 }}
                                />
                                <Typography variant="h6">{testimonial.name}</Typography>
                                <Typography variant="subtitle2" color="text.secondary">
                                    {testimonial.position}
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }} color="text.secondary">
                                    "{testimonial.quote}"
                                </Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
}

export default Testimonials;

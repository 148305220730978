// components/HowItWorks.js
import React from 'react';
import { Container, Typography, Grid, Card, CardContent, CardMedia } from '@mui/material';

function HowItWorks() {
    const steps = [
        {
            title: 'Discover',
            description: 'Explore our range of smart bots tailored for you.',
            image: '/discover.jpg',
        },
        {
            title: 'Connect',
            description: 'Integrate bots seamlessly with your platforms.',
            image: '/connect.jpg',
        },
        {
            title: 'Optimize',
            description: 'Automate tasks and focus on what truly matters.',
            image: '/optimize.jpg',
        },
    ];

    return (
        <Container sx={{ py: 8 }} id="how-it-works">
            <Typography variant="h4" align="center" gutterBottom>
                How It Works
            </Typography>
            <Grid container spacing={4} sx={{ mt: 4 }}>
                {steps.map((step, index) => (
                    <Grid item xs={12} md={4} key={index}>
                        <Card elevation={3}>
                            <CardMedia
                                component="img"
                                height="200"
                                image={step.image}
                                alt={step.title}
                            />
                            <CardContent>
                                <Typography variant="h6" component="div" gutterBottom>
                                    {step.title}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {step.description}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}

export default HowItWorks;

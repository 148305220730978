// components/TermsAndConditions.js
import React, { useState } from 'react';
import { Container, Typography, Box, Button } from '@mui/material';

function TermsAndConditions() {
    const [showMore, setShowMore] = useState(false);

    const handleToggle = () => {
        setShowMore(!showMore);
    };

    return (
        <Box sx={{ py: 8, backgroundColor: 'background.default' }} id="terms-&-conditions">
            <Container>
                <Typography variant="h4" align="center" gutterBottom>
                    Terms & Conditions
                </Typography>
                <Typography variant="body1" align="left" color="text.secondary" paragraph>
                    By using SmartLabBots, you agree to the following terms and conditions. Please read them carefully to understand your rights and obligations when using our platform.
                </Typography>

                <Box textAlign="center" mt={2}>
                    <Button variant="contained" color="white" onClick={handleToggle}>
                        {showMore ? 'Read Less' : 'Read More'}
                    </Button>
                </Box>

                {showMore && (
                    <>
                        <Typography variant="h6" gutterBottom mt={4}>
                            1. General Terms
                        </Typography>
                        <Typography variant="body1" align="left" color="text.secondary" paragraph>
                            These Terms and Conditions govern your use of SmartLabBots, including its services, website, and associated applications. By accessing or using the platform, you agree to comply with these terms. If you do not agree, you must discontinue your use of our services.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            2. Use of Google APIs
                        </Typography>
                        <Typography variant="body1" align="left" color="text.secondary" paragraph>
                            Our platform integrates with Google APIs to provide services such as Google Calendar management and Gmail functionalities. By granting us access, you acknowledge and agree to the following:
                        </Typography>
                        <ul>
                            <li>
                                Your data from Google Calendar and Gmail will be accessed only with your explicit consent via Google's OAuth 2.0 authorization framework.
                            </li>
                            <li>
                                Our use of data obtained through Google APIs will strictly comply with the
                                <a href="https://developers.google.com/terms/api-services-user-data-policy" target="_blank" rel="noopener noreferrer">Google API Services User Data Policy</a>, including Limited Use requirements.
                            </li>
                            <li>
                                We will only use Google API data to perform the specific tasks requested by you, such as creating, reading, updating, or deleting calendar events or composing, reading, and sending emails on your behalf.
                            </li>
                            <li>
                                Your data will not be shared with unauthorized third parties, used for advertising purposes, or retained beyond what is necessary to provide the requested services.
                            </li>
                        </ul>
                        <Typography variant="body1" align="left" color="text.secondary" paragraph>
                            You may revoke our access to your Google data at any time by visiting your
                            <a href="https://myaccount.google.com/permissions" target="_blank" rel="noopener noreferrer">Google Account Permissions</a>.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            3. Use of Paddle for Transactions
                        </Typography>
                        <Typography variant="body1" align="left" color="text.secondary" paragraph>
                            All transactions for subscriptions or services are processed through Paddle, our Merchant of Record. By purchasing through Paddle:
                        </Typography>
                        <ul>
                            <li>You agree to Paddle’s Terms and Conditions as outlined in their Seller Handbook.</li>
                            <li>Paddle handles billing, refunds, taxes (VAT, GST), and chargebacks.</li>
                            <li>Inquiries about billing should be directed to Paddle’s support team.</li>
                            <li>Your payment data is processed securely in compliance with PCI DSS standards.</li>
                        </ul>

                        <Typography variant="h6" gutterBottom>
                            4. Subscription Management
                        </Typography>
                        <Typography variant="body1" align="left" color="text.secondary" paragraph>
                            Subscriptions are billed on a recurring basis, as indicated during purchase. You are responsible for managing your subscription, including cancellations or plan changes. Refunds for partial subscription periods are not provided unless required by law.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            5. Complaint Policy
                        </Typography>
                        <Typography variant="body1" align="left" color="text.secondary" paragraph>
                            If you have a complaint, please contact us at support@smartlabbots.com. We aim to respond to all complaints within 5 business days. If your complaint is not resolved satisfactorily, please address admins@smartlabbots.com and we will resolve your issue ASAP.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            6. Disputes and Chargebacks
                        </Typography>
                        <Typography variant="body1" align="left" color="text.secondary" paragraph>
                            If you wish to dispute a charge, you can contact Paddle, our Merchant of Record, directly. Disputes and chargebacks may arise from unauthorized transactions, dissatisfaction with the product, or unrecognized charges. We recommend contacting support@smartlabbots.com first initiating a dispute to allow us to resolve the matter amicably.
                        </Typography>
                        <Typography variant="body1" align="left" color="text.secondary" paragraph>
                            Chargebacks will incur a fee as outlined in Paddle’s policies, and repeated disputes may result in suspension of your account. Please retain records of all communications for reference.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            7. User Conduct
                        </Typography>
                        <Typography variant="body1" align="left" color="text.secondary" paragraph>
                            Users must not:
                        </Typography>
                        <ul>
                            <li>Engage in unlawful activities or misuse the platform.</li>
                            <li>Reverse-engineer or exploit our software.</li>
                            <li>Access SmartLabBots without authorization or attempt to disrupt its functionality.</li>
                        </ul>
                        <Typography variant="body1" align="left" color="text.secondary" paragraph>
                            Violations of these terms may result in suspension or termination of your account.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            8. Intellectual Property
                        </Typography>
                        <Typography variant="body1" align="left" color="text.secondary" paragraph>
                            All content, trademarks, and software on SmartLabBots are owned by us or licensed to us. You are granted a limited, non-exclusive license to access and use our services for personal or business purposes but may not reproduce, distribute, or modify any content without written permission.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            9. Data Security & Fraud Prevention
                        </Typography>
                        <Typography variant="body1" align="left" color="text.secondary" paragraph>
                            We use industry-standard security measures to protect your personal and payment information. Paddle also provides fraud prevention tools to safeguard against unauthorized transactions.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            10. Limitation of Liability
                        </Typography>
                        <Typography variant="body1" align="left" color="text.secondary" paragraph>
                            SmartLabBots and its affiliates are not liable for any indirect, incidental, or consequential damages arising from your use of our platform. Our liability is limited to the maximum extent permitted by law.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            11. Termination of Services
                        </Typography>
                        <Typography variant="body1" align="left" color="text.secondary" paragraph>
                            We reserve the right to terminate or suspend your access to SmartLabBots at any time for violations of these terms or other applicable policies.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            12. Updates to Terms
                        </Typography>
                        <Typography variant="body1" align="left" color="text.secondary" paragraph>
                            We may update these Terms and Conditions periodically. Any significant changes will be posted on this page, and continued use of our platform constitutes acceptance of these changes.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            13. Governing Law
                        </Typography>
                        <Typography variant="body1" align="left" color="text.secondary" paragraph>
                            These Terms and Conditions are governed by the laws of Europe. Disputes will be subject to the exclusive jurisdiction of the courts in London.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            14. Contact Us
                        </Typography>
                        <Typography variant="body1" align="left" color="text.secondary" paragraph>
                            For questions about these terms, contact us at support@smartlabbots.com.
                        </Typography>

                        <Typography variant="body1" align="left" color="text.secondary" paragraph>
                            Last updated: November 2024
                        </Typography>
                    </>
                )}
            </Container>
        </Box>
    );
}

export default TermsAndConditions;

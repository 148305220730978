// components/HeroSection.js
import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-scroll';

const HeroContainer = styled(Box)(({ theme }) => ({
    height: '80vh',
    backgroundImage: 'url("/smartlabbotsbanner.jpg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    position: 'relative',
    zIndex: 1,
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Overlay for better text visibility
        zIndex: -1,
    },
}));

function HeroSection() {
    return (
        <HeroContainer>
            <Container>
                <Typography variant="h2" component="h1" gutterBottom>
                    Welcome to SmartLabBots
                </Typography>
                <Typography variant="h5" gutterBottom>
                    Centralizing AI-powered smart bots for a smarter future.
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    component={Link}
                    to="bots"
                    smooth
                >
                    Explore Bots
                </Button>
            </Container>
        </HeroContainer>
    );
}

export default HeroSection;

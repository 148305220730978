// components/FAQ.js
import React from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function FAQ() {
    const faqs = [
        {
            question: 'What platforms do your bots support?',
            answer: 'Our bots can be integrated with WhatsApp and Google Calendar at the moment.',
        },
        {
            question: 'Do I need coding skills to use the bots?',
            answer: 'No coding skills are required. Our bots are designed for ease of use.',
        },
        {
            question: 'How does Mobius handle my personal data?',
            answer: 'Mobius uses state-of-the-art encryption to ensure your data is safe. We do not store your personal calendar information beyond what is necessary to provide our services.',
        },
        {
            question: 'What is the pricing for your services?',
            answer: 'We offer a free trial, followed by monthly or yearly subscription plans. Check our Pricing section for more details.',
        },
        {
            question: 'Can I cancel my subscription at any time?',
            answer: 'Yes, you can cancel your subscription anytime from your account dashboard, and your access will remain active until the end of your current billing period.',
        },
        {
            question: 'What kind of support do you offer?',
            answer: 'We offer unlimited support via WhatsApp in up to 3 business days from your inquiry. You can also contact us through our website for any inquiries or issues.',
        },
        {
            question: 'Can Mobius integrate with other calendar platforms?',
            answer: 'Currently, Mobius supports Google Calendar. We are actively working on adding integrations with other popular calendar platforms.',
        },
    ];

    return (
        <Box sx={{ py: 8, backgroundColor: 'background.default' }} id="faq">
            <Container>
                <Typography variant="h4" align="center" gutterBottom>
                    Frequently Asked Questions
                </Typography>
                {faqs.map((faq, index) => (
                    <Accordion key={index} sx={{ mt: 2 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
                            <Typography variant="h6">{faq.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body1" color="text.secondary">
                                {faq.answer}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Container>
        </Box>
    );
}

export default FAQ;

import React from 'react';
import { Container, Typography, Grid, Card, CardContent, CardMedia, Button } from '@mui/material';
import { styled } from '@mui/system';

const BotCard = styled(Card)(({ theme }) => ({
    transition: 'transform 0.3s',
    '&:hover': {
        transform: 'translateY(-5px)',
        cursor: 'pointer', // Change cursor to pointer on hover
    },
}));

const BotCardContent = styled(CardContent)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center text and button
    justifyContent: 'center',
    textAlign: 'center',
}));

function BotsSection() {
    const bots = [
        {
            name: 'Mobius, The Time Assistant',
            description: 'Ready to own your time? Mobius is here to make it happen. This AI-driven, GPT-powered assistant transforms the way you handle your calendar. Whether it’s booking, editing, or canceling events, getting reminders, or summarizing meetings, Mobius makes sure you’re always in control – and it all happens through WhatsApp. Click Me and Make the magic happen now.',
            image: '/mobiustimebot2.jpg',
            link: 'https://wa.me/972544577310', // WhatsApp link with phone number
        }
        // Add more bots as needed
    ];

    return (
        <Container id="bots" sx={{ py: 8 }}>
            <Typography variant="h4" align="center" gutterBottom>
                Our Featured Bots
            </Typography>
            <Grid container spacing={4} sx={{ mt: 4 }}>
                {bots.map((bot, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <BotCard elevation={3}>
                            <CardMedia
                                component="img"
                                height="300"
                                image={bot.image}
                                alt={bot.name}
                            />
                            <BotCardContent>
                                <Typography variant="h5" component="div" gutterBottom>
                                    {bot.name}
                                </Typography>
                                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                                    {bot.description}
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    href={bot.link}
                                    target="_blank" // Opens WhatsApp in a new tab
                                    sx={{ mt: 2 }}
                                >
                                    Try Now
                                </Button>
                            </BotCardContent>
                        </BotCard>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}

export default BotsSection;

// components/Pricing.js
import React from 'react';
import { Container, Typography, Grid, Card, CardContent, Button, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function Pricing() {
    const plans = [
        {
            title: 'Free',
            price: 'No Charge',
            features: [
                'Access to basic event management',
                'Add, edit, and delete calendar events',
                'WhatsApp integration for reminders',
                'Voice to Text capabilities',
                'Emails review and response',
                'Limited to 30 events and emails per month'
            ],
        },
        {
            title: 'Monthly',
            price: '$15/month',
            features: [
                'Access to basic event management',
                'Add, edit, and delete calendar events',
                'WhatsApp integration for reminders',
                'Auto AI Events Summaries',
                'Voice to Text capabilities',
                'Emails review and response',
                'AI generated Email responses',
                'AI Events scheduling and recommendations',
                'Unlimited Access'
            ],
        },
        {
            title: 'Enterprise',
            price: 'Talk to us',
            features: [
                'Customized AI Assistant features',
                'Dedicated support team',
                'Wider scope understanding of tasks and emails',
                'Integration with company-wide Calendars and CRMs',
                'Real-time event tracking and reporting',
                'Personalized event analytics and insights',
                'Learning and Adopting to your organization',
                'On-demand training for your team',
                'Scalable solutions for large organizations',
                'Including all other capabilities',
            ],
        },
    ];

    return (
        <Container sx={{ py: 8 }} id="pricing">
            <Typography variant="h4" align="center" gutterBottom>
                Pricing Plans
            </Typography>
            <Grid container spacing={4} sx={{ mt: 4 }}>
                {plans.map((plan, index) => (
                    <Grid item xs={12} md={4} key={index}>
                        <Card elevation={3}>
                            <CardContent>
                                <Typography variant="h5" component="div" gutterBottom>
                                    {plan.title}
                                </Typography>
                                <Typography variant="h4" color="primary" gutterBottom>
                                    {plan.price}
                                </Typography>
                                <List>
                                    {plan.features.map((feature, idx) => (
                                        <ListItem key={idx}>
                                            <ListItemIcon>
                                                <CheckCircleIcon color="secondary" />
                                            </ListItemIcon>
                                            <ListItemText primary={feature} />
                                        </ListItem>
                                    ))}
                                </List>
                                <Button variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                                    Choose {plan.title}
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}

export default Pricing;

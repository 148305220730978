// components/Header.js
import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { HashLink as Link } from 'react-router-hash-link';
import { useTheme } from '@mui/material/styles';

function Header() {
    const theme = useTheme();

    // Function to scroll to the top of the page
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <AppBar
            position="sticky"
            elevation={0}
            sx={{ backgroundColor: theme.palette.secondary.main }}
        >
            <Toolbar>
                <Typography
                    variant="h6"
                    sx={{ flexGrow: 1, color: theme.palette.common.white }}
                    onClick={scrollToTop} // Scroll to top on click
                    style={{ cursor: 'pointer' }} // Pointer cursor for indication
                >
                    SmartLabBots
                </Typography>
                <Box>
                    {['About', 'Features', 'How It Works', 'Bots', 'Pricing', 'FAQ', 'Terms & Conditions', 'Privacy Policy', 'Contact'].map((item) => (
                        <Button
                            key={item}
                            component={Link}
                            to={`/#${item.toLowerCase().replace(/ /g, '-')}`}
                            smooth
                            sx={{ color: theme.palette.common.white }}
                        >
                            {item}
                        </Button>
                    ))}
                </Box>
            </Toolbar>
        </AppBar>
    );
}

export default Header;

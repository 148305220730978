import React from 'react';
import { Container, Typography, Grid, Card, CardContent, CardMedia } from '@mui/material';

function Features() {
    const features = [
        {
            title: "Accessibility",
            description: 'Seamlessly blend powerful AI features into your existing tools and platforms.',
            image: '/seamlessintegration.jpg',
        },
        {
            title: 'Empowerment',
            description: 'Equip yourself with AI that enhances your abilities and helps you work smarter, not harder.',
            image: '/empowerment.jpg',
        },
        {
            title: 'Adaptation',
            description: 'Experience AI that grows smarter over time, enhancing its support.',
            image: '/innovativeai.jpg',
        },
    ];

    return (
        <Container id="features" sx={{ py: 8 }}>
            <Typography variant="h4" align="center" gutterBottom>
                Why Choose SmartLabBots?
            </Typography>
            <Grid container spacing={4} sx={{ mt: 4 }}>
                {features.map((feature, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card elevation={3}>
                            <CardMedia
                                component="img"
                                height="200"
                                image={feature.image}
                                alt={feature.title}
                            />
                            <CardContent>
                                <Typography variant="h5" component="div" gutterBottom>
                                    {feature.title}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {feature.description}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}

export default Features;

// components/Footer.js
import React from 'react';
import {
    Container,
    Typography,
    Box,
    IconButton,
    TextField,
    Button,
    Stack,
} from '@mui/material';
import { Facebook, Twitter, LinkedIn, Instagram } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

function Footer() {
    const theme = useTheme();

    return (
        <Box
            component="footer"
            sx={{
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.common.white,
                py: 6,
            }}
        >
            <Container maxWidth="lg"> {/* Increase width to "lg" */}
                {/* Center the content */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        maxWidth: '800px', // Limit the width for readability
                        mx: 'auto', // Center the content
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        Stay Connected
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Subscribe to our newsletter for the latest updates.
                    </Typography>

                    {/* Subscription form */}
                    <Box
                        component="form"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mt: 2,
                            width: { xs: '100%', sm: '75%', md: '50%' }, // Increase width based on screen size
                        }}
                    >
                        <TextField
                            variant="filled"
                            label="Email Address"
                            type="email"
                            fullWidth
                            sx={{
                                mr: 2,
                                backgroundColor: theme.palette.common.white,
                                borderRadius: 1,
                                '& .MuiFilledInput-root': {
                                    backgroundColor: theme.palette.common.white,
                                },
                            }}
                            InputLabelProps={{
                                sx: { color: theme.palette.text.primary },
                            }}
                        />
                        <Button variant="contained" color="primary" sx={{ width: 'auto', px: 4 }}>
                            Subscribe
                        </Button>
                    </Box>

                    {/* Social media icons */}
                    <Stack direction="row" spacing={4} sx={{ mt: 4, justifyContent: 'center' }}>
                        <IconButton
                            href="https://facebook.com/yourpage"
                            target="_blank"
                            sx={{ color: theme.palette.common.white }}
                        >
                            <Facebook fontSize="large" />
                        </IconButton>
                        <IconButton
                            href="https://twitter.com/yourprofile"
                            target="_blank"
                            sx={{ color: theme.palette.common.white }}
                        >
                            <Twitter fontSize="large" />
                        </IconButton>
                        <IconButton
                            href="https://linkedin.com/yourprofile"
                            target="_blank"
                            sx={{ color: theme.palette.common.white }}
                        >
                            <LinkedIn fontSize="large" />
                        </IconButton>
                        <IconButton
                            href="https://instagram.com/yourprofile"
                            target="_blank"
                            sx={{ color: theme.palette.common.white }}
                        >
                            <Instagram fontSize="large" />
                        </IconButton>
                    </Stack>

                    {/* Footer text */}
                    <Typography variant="body2" sx={{ mt: 4 }}>
                        &copy; 2024 SmartLabBots. All rights reserved.
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
}

export default Footer;

// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#4CAF50', // Primary green color
        },
        secondary: {
            main: '#81C784', // Lighter green for accents
        },
        background: {
            default: '#FFFFFF', // White background
        },
        text: {
            primary: '#263238', // Dark gray for text
            secondary: '#4E342E', // Brownish for secondary text
        },
        common: {
            white: '#FFFFFF',
        },
    },
    typography: {
        fontFamily: 'Montserrat, Arial, sans-serif',
        h1: {
            fontWeight: 700,
        },
        h4: {
            fontWeight: 600,
        },
        body1: {
            lineHeight: 1.6,
        },
    },
});

export default theme;

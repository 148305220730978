// components/AboutUs.js
import React from 'react';
import { Container, Typography, Box } from '@mui/material';

function AboutUs() {
    return (
        <Box sx={{ py: 8, backgroundColor: 'background.default' }} id="about">
            <Container>
                <Typography variant="h4" align="center" gutterBottom>
                    About SmartLabBots
                </Typography>
                <Typography variant="body1" align="center" color="text.secondary" paragraph>
                    At SmartLabBots, we're revolutionizing the way businesses interact with technology. Our AI-powered bots are designed to automate tasks, enhance productivity, and drive innovation.
                </Typography>
            </Container>
        </Box>
    );
}

export default AboutUs;
